@import "../shared_theme/fuse";
$asset-base-path: '/assets/cycle2et3tnecanope' !default;

@import "../cycle1tnecanope/variables";
@import "../cycle1tnecanope/variables-buttons-tabs";
@import "../cycle1tnecanope/variables-chips";
@import "../cycle1tnecanope/variables-activities-colors";
@import "/src/app/@modules/css-library/scss/abstracts";

@import "../shared_theme/default_theme";
@import "../shared_theme/main";

@import "modules/_corpus";

app-lesson-page .files .content {
    mat-card {
        border: solid 1px $basic-page__icon--background-color;
    }
    .custom-nav #prev, .custom-nav #next {
        background: var(--bje-jaune);
        mat-icon{
            color: black;
        }
    }
}